<template>
  <v-container fluid>
    <v-row dense class="mb-4 mx-1">
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <h1>{{ `${ $t('Dapps') } ${ $t('Activity') }` }}</h1>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" lg="4">
            <v-text-field
              v-model="address"
              type="text"
              :placeholder="`${$t('Token')} ${$t('Address')}`"
              hide-details
              dense
              filled
              @keyup="onKeyUpSearch"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="4">
            <v-text-field
              v-model="name"
              type="text"
              :placeholder="`${$t('Token')} ${$t('Name')}`"
              hide-details
              dense
              filled
              @keyup="onKeyUpSearch"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <Menu
              text-color="white"
              :button-size="`primary uncapitalize`"
              :selected-text="$t('Chains')"
              :list-items="chains"
              key-item="dropdown_values"
              :has-check-box="true"
              @click="onSelectChain($event)"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="2"
          >
            <v-row dense align="center">
              <v-col cols="4" class="text-right">
                <small class="grey--text">{{ $t('Rows') }}</small>
              </v-col>
              <v-col cols="4" class="">
                <Menu
                  :selected-text="`${rowsPerPage.toString()}`"
                  :list-items="rows"
                  :button-size="`common_background`"
                  @click="onChangeRowsPerPage($event)"
                />
              </v-col>
              <v-col cols="2">
                <v-btn
                  block
                  :value="true"
                  class="common_background px-n3 flex-grow-1 no-hover-bg"
                  @click="onPreviousPage"
                >
                  <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn
                  block
                  :value="false"
                  class="common_background px-n3 flex-grow-1 no-hover-bg"
                  @click="onNextPage"
                >
                  <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="text-right">
            <v-data-table
              :headers="headers"
              :items="listActivity"
              :loading="loading"
              loading-text=""
              :headers-length="9"
              :header-props="{ sortIcon: 'mdi-chevron-up' }"
              :loader-height="1"
              no-data-text="No Record Found."
              item-key="bcm_coin_id"
              hide-default-footer
              disable-pagination
              mobile-breakpoint="0"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-center">
                    <span class="ml-2">{{ item.calc_rank }}</span>
                  </td>
                  <td class="text-left sticky">
                    <div>
                      <a
                        :key="item.id"
                        text
                        plain
                        class="body-2"
                        @click="onCurrencyActivityView(item)"
                      >
                        {{ item.token_address }}
                      </a>
                    </div>
                  </td>
                  <td class="text-left currency-details secondary--text">
                    <div class="d-flex pt-2">
                      <div>
                        <img
                          v-if="item.logo === null"
                          :alt="item.name"
                          :src="require(`@/assets/images/no-image.png`)"
                          class="currency-logo"
                        />
                        <img
                          v-else
                          :alt="item.name"
                          :lazy-src="item.logo"
                          :src="item.logo"
                          class="currency-logo"
                          @error="helper.imgerror"
                        />
                      </div>
                      <div class="ml-2">
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-left">
                    <span>{{ item.symbol }}</span>
                  </td>
                  <td class="text-right">
                    <span>{{ helper.convertToBigNumber(item.trans_count, 0) }}</span>
                  </td>
                  <td class="text-right">
                    <span>{{ helper.abbreviateNumber(item.trans_value) }}</span>
                  </td>
                  <td class="text-right">
                    <span>{{ helper.convertToBigNumber(item.sent_address_count, 0) }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="mx-1 mb-1" dense>
          <v-col
            cols="12"
            sm="6"
            lg="2"
            offset-sm="6"
            offset-lg="10"
          >
            <v-row dense align="center" class="mt-1">
              <v-col cols="4" class="text-right">
                <small class="grey--text">{{ $t('Rows') }}</small>
              </v-col>
              <v-col cols="4" class="">
                <Menu
                  :selected-text="`${rowsPerPage.toString()}`"
                  :list-items="rows"
                  :button-size="`common_background`"
                  @click="onChangeRowsPerPage($event)"
                />
              </v-col>
              <v-col cols="2">
                <v-btn
                  block
                  :value="true"
                  class="common_background px-n3 flex-grow-1 no-hover-bg"
                  @click="onPreviousPage"
                >
                  <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn
                  block
                  :value="false"
                  class="common_background px-n3 flex-grow-1 no-hover-bg"
                  @click="onNextPage"
                >
                  <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import helper from '@/utils/helper'
import _ from 'lodash'
import { get, dispatch, call, commit } from 'vuex-pathify'
import Menu from '@/components/atoms/Menu'

export default {
  name:'DappActivity',
  components: {
    Menu,
  },
  data: () => ({
    helper: helper,
    address: '',
    name: '',
    chain: '',
    loading: true,
    timeout: null,
    selected_chain: 'ALL',
    page: 1,
    rows: [100, 200, 300, 400],
    rowsPerPage: 100,
    headers: [
      {
        text: 'Rank',
        value: 'calc_rank',
        align: 'center',
        width: '5%',
        sortable: true,
      },
      {
        text: 'Token Address',
        value: 'token_address',
        align: 'left',
        class: 'sticky',
        width: '20%',
        sortable: true,
      },
      {
        text: 'Name',
        value: 'name',
        align: 'left',
        width: '10%',
        sortable: true,
      },
      {
        text: 'Symbol',
        value: 'symbol',
        align: 'left',
        width: '10%',
        sortable: true,
      },
      {
        text: 'Transaction Count',
        value: 'trans_count',
        align: 'right',
        width: '15%',
        sortable: true,
      },
      {
        text: 'Value',
        value: 'trans_value',
        align: 'right',
        width: '15%',
        sortable: true,
      },
      {
        text: 'Sent Count',
        value: 'sent_address_count',
        align: 'right',
        width: '15%',
        sortable: true,
      },
    ],
  }),
  computed: {
    listActivity: get('overview/dappActivities'),
    chains: {
      get: get('overview/chains'),
      set: (value) => commit('overview/SET_CHAIN_ITEMS', value),
    },
  },
  beforeMount() {
    this.onPopulateDropdowns()
    this.onFetchActivity()
  },
  methods: {
    ...call('overview',['DROPDOWN_REQUEST', 'DAPP_ACTIVITY_REQUEST']),
    onPreviousPage() {
      if (this.page <= 1) {
        this.page = 1
      } else {
        this.page -= 1
      }
      this.onFetchActivity()
    },
    onNextPage() {
      if (this.listActivity.length <= 0) {
        const temp = this.page

        this.page = temp
      } else {
        this.page += 1
      }
      this.onFetchActivity()
    },
    addSelected(obj, id) {
      const idx = _.findIndex(this.$store.state.overview[obj], (o) => {
        return o.dropdown_values === id.dropdown_values
      })

      if (id.dropdown_values === 'ALL') {
        this.$store.state.overview[obj].forEach((el, index) => {
          commit(`overview/SET_SELECTED_${obj.toUpperCase()}_ITEMS`, {
            index: index, value: index === idx ? true : false,
          })
        })
      } else {
        commit(`overview/SET_SELECTED_${obj.toUpperCase()}_ITEMS`, { index: 0, value: false })
        commit(`overview/SET_SELECTED_${obj.toUpperCase()}_ITEMS`, { index: idx, value: !id.selected })
      }

      this[obj] = Object.assign([], this.$store.state.overview[obj])
    },
    onKeyUpSearch() {
      const self = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        self.onFetchActivity()
      }, 500)
    },
    onChangeRowsPerPage(row) {
      this.rowsPerPage = row
      this.onFetchActivity()
    },
    onSelectChain(item) {
      this.selected_chain = item
      this.addSelected('chains', item)
      this.onFetchActivity()
    },
    async onPopulateDropdowns(key) {
      const payload = {
        type: 'CHAIN',
      }

      await this.DROPDOWN_REQUEST(payload)
    },
    onCurrencyActivityView(item) {
      this.$router.push({
        name: 'DappActivityDetails',
        path: `/dapp-activity/details/${item.chain}/${item.token_address}`,
        params: {
          token: item.token_address,
          name: item.chain,
          id: item.chain,
        },
      })
    },
    async onFetchActivity() {
      let chains = ''

      this.loading = true
      _.forEach(this.chains, (o) => {
        chains += o.selected ? `${o.dropdown_values},` : ''
      })

      chains = chains.substring(0, chains.length - 1)

      const payload = {
        token_address: this.address ? this.address : null,
        token_name: this.name ? this.name : null,
        chain: chains.toUpperCase() === 'ALL' || _.isEmpty(chains) ? null : chains,
        rows: this.rowsPerPage,
        page: this.page,
      }

      await this.DAPP_ACTIVITY_REQUEST(payload)
      this.loading = false
    },
  },

}
</script>

<style lang="scss">
@import "@/assets/styles/variables.scss";
.currency-logo {
  height: 25px;
}
</style>
